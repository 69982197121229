import React, {useRef, useImperativeHandle, forwardRef} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import sanityClient from "../client";
import imageUrlBuilder from '@sanity/image-url';
import Portfolio from "../components/portfolio";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import {useContent} from '../hooks/ContentContext';



let PortfolioRR = (props, ref) => {
    const sanityImageBuilder = imageUrlBuilder(sanityClient);
    const slider = useRef(null);
    const {state: {portfolioContent}} = useContent();

    function getSanityImageUrl(source) {
        return sanityImageBuilder.image(source)
      }

    useImperativeHandle(ref, () => ({
        slickNext: () => {
            slider.current.slickNext();
        },
        slickPrev: () => {
            slider.current.slickPrev();
        }
    }));

    const carouselSettings = {
        arrows: false,
        autoplay: false,
        autoplaySpeed: 5000,
        dots: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        infinite: true,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                }
            }
        ]
    };

    return (
        <div className="content-body section">




            <div className="section-wrap section">
                <Container fluid>
               
                    <Row>
                        <Col className={'p-0'}>
                     
                            <Slider ref={slider} {...carouselSettings}>
                            {portfolioContent && portfolioContent.map((portfolio,index) => (
                                    <div key={index} className="col pl-5 pr-5">
                                        <Portfolio
                                            title={portfolio.titel}
                                            thumb={getSanityImageUrl(portfolio.projektbild.asset).height(500).auto("format").quality(85).url()} //.focalPoint(portfolio.projektbild.hotspot.x, portfolio.projektbild.hotspot.y).fit("crop").crop("focalpoint").url()
                                            category={portfolio.projekttyp}
                                            link={portfolio.link.current}
                                            classes={'portfolio-screen-height'}
                                        />
                                    </div>
                                ))}
                                {/** getSanityImageUrl(portfolio.projektbild.asset).height(350).focalPoint(portfolio.projektbild.hotspot.x, portfolio.projektbild.hotspot.y).fit("crop").crop("focalpoint").url()}
                                {portfolioData.map((portfolio,index) => (
                                    <div key={index} className="col pl-5 pr-5">
                                        <Portfolio
                                            title={portfolio.title}
                                            thumb={portfolio.thumb}
                                            category={portfolio.category}
                                            link={portfolio.link}
                                            classes={'portfolio-screen-height'}
                                        />
                                    </div>
                                ))}
                                */}
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default forwardRef(PortfolioRR);
