import React from 'react';
import PageContainer from "../../container/CustomPage";

import Impressum from "../../container/Impressum";
{/**  <PageContainer classes={'bg-grey'}> */}
const ContentAboutPage = () => {
    return (
       
            <div class="bg-projekte section">
           <Impressum type={'page'}/>
           </div>

    );
};

export default ContentAboutPage;