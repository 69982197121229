import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// File imported
import Header from "../components/header/HeaderOne";
import SideHeader from "../components/SideHeader";
import BannerPersonalPortfolio from "../components/HeroBanner/PersonalPortfolio";
import PersonalPortfolioService from "../container/service";
import PersonalPortfolioTeam from "../container/team";
import Contact from "../container/contact";
import PortfolioSlider from "../components/footer/FooterSlider";
import FooterNav from "../components/footer/FooterThree";
import SectionTitle from "../components/SectionTitleBlack";

import PortfolioRR from "./PortfolioRR";

const HomeRR = () => {

    const isSticky = e => {
        const header = document.querySelector('.header-area');
        const scrollTop = window.scrollY;
        if(header)scrollTop >= 200 ? header.classList.add('sticky') : header.classList.remove('sticky');
    };

    useEffect(() => {
        window.addEventListener('scroll', isSticky);

        return () => {
            window.removeEventListener('scroll', isSticky);
        }
    }, []);

    const slider = React.useRef(null);

    const SlideNext = () => {
        slider.current.slickNext();
    };

    const SlidePrev = () => {
        slider.current.slickPrev();
    };

    return (
        <div className={'main-wrapper p-0'}>



            <Header classes={'header-area section fixed-top'} />
            <SideHeader mobile={true} />
            <BannerPersonalPortfolio />
            <div className="content-body section" style={{ height: "100%" }}>
                <Container>
                    <Row>
                        <Col  >

                            <div className="rr_introText">
                                <h2>Rabot Rummel</h2>
                                <p>
                                    Rabot Rummel produziert seit 2013 visuelle, audiovisuelle und interaktive Präsentationsformen für digitale Medien und virtuelle Raumkonzepte. Wir verbinden die Möglichkeiten digitaler Technik mit Kreativität, Erfahrung und konzeptionellem Verstand. Dabei liegt unsere Überzeugung in der Einzigartigkeit jedes Projektes und unser Herzblut in der kontinuierlichen Erweiterung digitaler Visualisierungsmöglichkeiten.
                            </p>
                                <p>
                                    Vom Storyboard bis zum fertigen Film, von der vagen Idee bis zur Programmierung und Steuerung einer interaktiven Rauminstallation - wir entwickeln und betreuen Ihre Projekte vom ersten bis zum letzten Schritt.
                            </p>
                                <p>Unsere Kunden kommen aus den Bereichen Werbung, Wirtschaft, Kunst und Kultur. Unser Können konnten wir bereits bei vielen Projekten unter Beweis stellen, unter anderem bei Produktionen für Continental, Johnson Controls, Klimapakt Flensburg, Landesbank Berlin, Landesmuseum Hannover, Lotto Niedersachsen, Staatsoper Hannover, Stadt Bensheim, Stadtwerke Flensburg, VGH Versicherungen, Volkswagen …
                            </p>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br />
                            <SectionTitle
                                title={"Portfolio"}
                                content={""}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>



            <PortfolioRR ref={slider} />
            <PortfolioSlider position={'static'} prev={SlidePrev} next={SlideNext} />
            {/**   <PersonalPortfolio/>*/}
            <PersonalPortfolioService />
            <PersonalPortfolioTeam />
            <div className="bg-grey section ">
                <Contact />
            </div>
            <FooterNav position={'static'} />

        </div>
    );
};

export default HomeRR;
