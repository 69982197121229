import React from 'react';

// File imported
import Header from '../../components/header/HeaderOne'
import SideHeader from "../../components/SideHeader";
import PortfolioDefault from "../../rrComponents/PortfolioRR";
import Footer from "../../components/footer/FooterOne";
import {Helmet} from "react-helmet";


const HomeDefault = () => {

    const slider  = React.useRef(null);

    const SlideNext = ()=>{
        slider.current.slickNext();
    };

    const SlidePrev = ()=>{
        slider.current.slickPrev();
    };

    return (
        <div className={'main-wrapper'}>
            <Helmet>
            <title>Portfolio</title>
        <meta name="description" content="Wir produzieren Augmented Reality, Virual Reality, Film, 2D und 3D Animationen, Webanwendungen und Interaktive Installationen." />
    </Helmet>

            <Header/>
            <SideHeader mobile={true}/>
            <PortfolioDefault ref={slider}/>
            <Footer prev={SlidePrev} next={SlideNext}/>
        </div>
    );
};

export default HomeDefault;
