import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Form from "../../components/form";
import QuickContact from "../../components/QuickContact";
import Map from "../../components/map";

const Contact = () => {
    return (
            <div
                className="section-wrap section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
                <Container>
                    <Row>
                        <Col lg={6} className={'mb-sm-45 mb-xs-45'}>
                            <Row>
                                <Col lg={10} className={'mb-45'}>
                              
                                <div className="contact-form">
                                        <h3>Kontakt</h3>
                                       <Form/>
                                    </div>
                                </Col>

                                <Col lg={10}>
                               <br/>
                                    <QuickContact
                                        phone={'+49 151 200 33 946'}
                                        email={'kontakt@rabotrummel.de'}
                                        location={'Lister Höfe. Spichernstraße 24, 30161 Hannover'}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={6}>
                            {/**                             <Map
                                text={'Sie haben eine vage Idee, ein ausgereiftes Konzept oder suchen nach einer Teillösung für Ihr Projekt – sprechen Sie uns an! Wir beraten Sie kompetent.'}
                                lat={52.359168}
                                long={9.713191}
                                
                            />
                            */}

<Row>
<Col lg={10} className={'mb-45'}>


                <div className="contact-availability">
                    <h3>Beratung</h3>
                    <p>Sie haben eine vage Idee, ein ausgereiftes Konzept oder suchen nach einer Teillösung für Ihr Projekt – sprechen Sie uns an! Wir beraten Sie kompetent.</p>
                <br/><br/>
                </div>

                            <img  src={require('../../assets/images/rabotoffice.jpg').default} alt="navigation"/>
                            </Col>
                                <Col lg={10} className={'mb-45'}>
                            
                             <a href="https://goo.gl/maps/V2j4KwxLdzyEp7Rq9" target="_blank" rel="noopener noreferrer"> <img  src={require('../../assets/images/navigation.jpg').default} alt="navigation"  /></a>
                             &copy;Mapbox &copy;OpenStreetMap <br/> Die Karte verweist zur Routenplanung auf Google Maps.

                            </Col>
                           

                            </Row>

                        </Col>
                    </Row>
                </Container>
            </div>
      
    );
};

export default Contact;