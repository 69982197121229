import React, {Fragment} from 'react';
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import Datenschutz from "../../templates/Datenschutz";
import FooterNav from "../../components/footer/FooterThree";

const AboutPage = () => {
    return (
        <Fragment>
            <Header classes={'project-header fixed-top'}/>
            <SideHeader mobile={true}/>
            <Datenschutz/>
            <FooterNav position={'static'}/>
        </Fragment>
    );
};

export default AboutPage;