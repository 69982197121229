import React,{useEffect} from 'react';
import {useContent} from '../../../../hooks/ContentContext';
import BlockContent from '@sanity/block-content-to-react';

const PortfolioDetailsContent = ({titel, projekttyp, beschreibung, meta}) => {

    const {state: {portfolioContent}} = useContent();
 
    return (
        <div className="portfolio-details-content">

            <span className="category">{projekttyp}</span>

            <h1 className="title">{titel}</h1>

 {<BlockContent blocks={beschreibung}/>}  {/*beschreibung.split('\n').map(c => {
                  return ( <p> {c} </p> ) 
                   })*/}

          

            <ul className="project-info">
                <li><span>Client:</span> {meta.klient}</li>
                <li><span>Date:</span> {meta.datum}</li>
                <li><span>Website:</span>
                    <a href={meta.webseite}
                       target="_blank"
                       rel="noopener noreferrer">{meta.webseite}
                    </a>
                </li>
                <li>
                    <span>Service:</span>
                    <p>{meta.service}</p>
                    {/*meta.services.map((service,index) => (
                        <a key={index} href="/">{service}</a>
                    ))*/}
                </li>
                {/**
                <li>
                    <span>Kooperation:</span>
                    {meta.kooperation.map((kooperation,index) => (
                        <a key={index}>{kooperation }</a>
                    ))}
                </li>

                 */}
            </ul>

            <div className="project-share">
                <span>Share:</span>
                <a href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href}><i className="fa fa-facebook"/></a>
                <a href={"https://twitter.com/home?status=="+window.location.href}><i className="fa fa-twitter"/></a>
                <a href={"https://pinterest.com/pin/create/button/?url="+window.location.href+"&media=&description="}><i className="fa fa-pinterest-p"/></a>
                <a href={"https://plus.google.com/share?url="+window.location.href}><i className="fa fa-google-plus"/></a>
            </div>

        </div>
    );
};

export default PortfolioDetailsContent;