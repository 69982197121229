import React,{useState, useRef} from 'react';
import Input from "../input";

const Form = () => {

    var formStructure={
        "name":"",
        "email":"",
        "message":""
    
      };
    const formRef=useRef(null);
    const [form, setForm] = useState(formStructure);
    //const formSend = () => history.push('FormSucess');

    function sendRequest(e){
      alert("Danke für Ihre Nachricht. Wir melden uns.");

        e.preventDefault();
          let message=JSON.stringify({...form});
         
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ "message": message,
                                "email": form.email,
                                "name": form.name
        })
      };
     
      
        //Mail to Sender
        fetch("https://rabotrummel.de/.netlify/functions/SendmailResponse", requestOptions)
        .then(res => console.log("Mailto sender res:"+res))
        .then(
          data => {
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log("SENDMAIL ERROR:"+error)
          }
        )
  
        //Mail to us
        fetch("https://rabotrummel.de/.netlify/functions/Sendmail", requestOptions)
        .then(res => console.log("Mailto res:"+res))
        .then(
          data => {
         //  formSend();
         //Reset form when success
         formRef.current.reset();
         setForm({
           "name":"",
           "email":"",
           "message":""
       
         });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            alert("Etnschuldigung, leider konnte Ihre Nachricht nicht verschickt werden. Versuchen Sie es noch einmal oder kontaktieren Sie uns auf einem anderen weg. Error:"+error);
            console.log("SENDMAIL ERROR:"+error)
          }
        );
       
    }

    function onFormChange(e){
        console.log("FORMCHANGE");
        console.log(form);
        let changedForm=form;
        changedForm[e.target.id]=e.target.value;
        changedForm["subject"]="Danke für ihre Nachricht an Rabot Rummel. "+form.name; //Change subject for sender...
        setForm(changedForm);
      }

    return (
        <form ref={formRef} id="contact-form" onSubmit={sendRequest}>
            <input type={'text'} id={'name'} placeholder={'Name'} onChange={onFormChange} required={true}/>
            <input type={'email'} id={'email'} placeholder={'Email'} onChange={onFormChange} required={true}/>
            <textarea type={'textarea'} id={'message'} placeholder={'Nachricht'} onChange={onFormChange} required={true}/>
            <button type={'submit'}>Senden</button>
        </form>
    );
};

export default Form;