import React from 'react';
import {Container,Carousel} from 'react-bootstrap';
import {useContent} from '../../hooks/ContentContext';
import sanityClient from "../../client";
import imageUrlBuilder from '@sanity/image-url';

const HeroBannerContainer = ({children, overlay, bg}) => {
    const sanityImageBuilder = imageUrlBuilder(sanityClient);
    const {state: {headerPictures}} = useContent();

    function getSanityImageUrl(source) {
        return sanityImageBuilder.image(source)
      }
      

    //style={{backgroundImage: `url(${bg})`}}
    return (
        <div className={`section main-slider-1 ${overlay ? 'banner-overlay' : null}`} > 
<Carousel className="carousel" interval={5000}>
{headerPictures &&
headerPictures.map((picture,index)=>(
    <Carousel.Item key={picture._id}>
                    <img className="backImage" width={1920} height={800} alt="backgroundImage" src={getSanityImageUrl(picture.projektbild.asset).width(1920).height(800).auto("format").quality(85).url()} />
                    </Carousel.Item>
))
}</Carousel>

              


            <Container style={{position:"absolute",zIndex:"100",left:"0px"}}>
                {children}
            </Container>
        </div>
    );
};

export default HeroBannerContainer;