import React,{useEffect} from 'react';
import {useContent} from '../hooks/ContentContext';


function Content(props) {

    const {dispatchContent} = useContent();

    function addContent(contentData){
        dispatchContent({ type:"addData", contentData });
      }




    useEffect(() => {
        if (props.portfolioContent) addContent({ "portfolioContent": props.portfolioContent });
    }, [props.portfolioContent]);

    useEffect(() => {
        if (props.headerPictures) addContent({ "headerPictures": props.headerPictures });
    }, [props.headerPictures]);

    useEffect(() => {
        if (props.team) addContent({ "team": props.team });
    }, [props.team]);

    useEffect(() => {
        if (props.leistungen) addContent({ "leistungen": props.leistungen });
    }, [props.leistungen]);
        
    return (
        <React.Fragment>
            
        </React.Fragment>
    );
}

export default Content;