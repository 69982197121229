import React from 'react';
import { Link } from 'react-router-dom';
const Team = ({ name, link, designation, profilePhoto, socials }) => {
    return (

        <div className="team-item">

            <div className="team-inner">

                {link ?
                <div>
                    <Link to={`${process.env.PUBLIC_URL + "/" + link.current + "/"}`} className={'d-block'}
                        onClick={() => {
                            window.scrollTo(0, 0);
                        }}
                    >
                        <img src={profilePhoto} alt={name} />
                        <div className="team-content">
                            <h4>{name}</h4>
                            <span>{designation}</span>
                            {/**  
                    <div className="team-social">
                        {socials.map(icon => (
                            <a key={icon.id}
                               href={`https://${icon.media}.com/${icon.username}`}
                               target="_blank"
                               rel="noreferrer noopener"
                            >
                                <i className={`fa fa-${icon.media}`}/>
                            </a>
                        ))}
                    </div>
                    */}
                        </div>
                    </Link>
                    
                    </div>
                    :
                    <div>
                    <img src={profilePhoto} alt={name} />
                    <div className="team-content">
                            <h4>{name}</h4>
                            <span>{designation}</span>
                    </div>
                    </div>
                }

                
              
                  
            </div>

        </div>

    );
};

export default Team;