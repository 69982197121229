import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import SectionTitle from "../../components/SectionTitleBlack";
import Team from "../../components/team";
import teams from '../../data/team';

import {useContent} from '../../hooks/ContentContext';
import sanityClient from "../../client";
import imageUrlBuilder from '@sanity/image-url';

const PersonalPortfolioTeam = () => {

    const sanityImageBuilder = imageUrlBuilder(sanityClient);
    const {state: {team}} = useContent();

    function getSanityImageUrl(source) {
        return sanityImageBuilder.image(source)
      }

    return (
        <div className="section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
            <div className="section-wrap section">
                <Container>
                    <Row>
                        <Col>
                            <SectionTitle
                                title={'Ansprechpartner'}
                                content={''}
                            />
                        </Col>
                    </Row>

                    <Row className={'mbn-30'}>
                        {team && team.slice(0, 3).map(member => (
                            <Col key={member._id} md={6} lg={4} className={'mb-30'}>
                                <Team
                                    name={member.name}
                                    designation={member.titel}
                                    profilePhoto={getSanityImageUrl(member.bild.asset).width(415).height(528).auto("format").quality(85).url()}
                                    socials={member.socials}
                                    link={member.link? member.link:null}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default PersonalPortfolioTeam;