import React from 'react';
import PageContainer from "../../container/CustomPage";

import AboutMe from "../../container/AboutMe";

const ContentAboutPage = (Props) => {
    return (
        <div className="bg-projekte section">
           <AboutMe id={Props.id} type={'page'}/>
        </div>
    );
};

export default ContentAboutPage;