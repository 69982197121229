import React, {Fragment,useEffect,useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';


/*
* @ Component Imported
* */
import Portfolio from "./pages/home/HomeDefault";
import HomeRR from "./rrComponents/HomeRR";
import PortfolioDetailsPage from "./pages/portfolio/details";
import ImpressumPage from "./pages/Impressum";
import DatenschutzPage from "./pages/Datenschutz";
import ServicePage from "./pages/service";
import TeamPage from "./pages/team";
import About from "./pages/about";
import ContactPage from "./pages/contact";
import ContactPageDanke from "./pages/contactDanke";
import ErrorPage from "./pages/404Error";
import aboutData from './data/AboutMe';
import sanityClient from "./client.js";
import Content from './pages/Content.js';
import {ContentProvider} from './hooks/ContentContext';
import {Helmet} from "react-helmet";



const App = () => {

       const [portfolioContent,setPortfolioContent]=useState(null);
       const [headerPictures,setHeaderPictures]=useState(null);
       const [team,setTeam]=useState(null);
       const [leistungen,setLeistungen]=useState(null);

       useEffect(() => {

              sanityClient.fetch(
                /* groq */`*[_type == "projects"] | order(orderRank){
                _id,
                titel,
                link,
                projekttyp,
                projektbild,
                beschreibung,
                projektdatum,
                klient,
                webseite,
                service,
                medien,
                "mediaURLs": medien[].media.asset->url
            }`).then((data) => {
              setPortfolioContent(data);
              }).catch(console.error);

              sanityClient.fetch(
                     /* groq */`*[_type == "headerPictures"] | order(orderRank){
                     _id,
                     titel,
                     projektbild
                 }`).then((data) => {
                   setHeaderPictures(data);
                   }).catch(console.error);
        

          sanityClient.fetch(
              /* groq */`*[_type == "team"] | order(orderRank){
              _id,
              link,
              name,
              titel,
              bild,
              beschreibung
          }`).then((data) => {
            setTeam(data);
            }).catch(console.error);

            sanityClient.fetch(
              /* groq */`*[_type == "leistungen"] | order(orderRank){
              _id,
              leistung,
              beschreibung
          }`).then((data) => {
            setLeistungen(data);
            }).catch(console.error);
   }, []);


    return (
        <Fragment>
               <Helmet>
<title>Rabot Rummel - Digitale Medien</title>
        <meta name="description" content="Rabot Rummel produziert seit 2013 visuelle, audiovisuelle und interaktive Präsentationsformen für digitale Medien und virtuelle Raumkonzepte." />
    </Helmet>
            <Router>
                   <ContentProvider>
                          <Content portfolioContent={portfolioContent} headerPictures={headerPictures} team={team} leistungen={leistungen}/>
                <Switch>
                     { portfolioContent && portfolioContent.map(portfolio => (   
                        <Route key={portfolio._id} path={`${process.env.PUBLIC_URL +"/"+ portfolio.link.current}`}
                            render={(props) => (
                            <PortfolioDetailsPage {...props} id={portfolio._id} />
                            )}/>
                     ))}

                     {team && team.map((about,index) => (   
                        <Route key={about._id} path={`${process.env.PUBLIC_URL +"/"+ about.link.current}`}
                            render={(props) => (
                            <About {...props} id={index} />
                            )}/>
                     ))}

                   

                    <Route path={`${process.env.PUBLIC_URL + "/Kontakt"}`}
                           component={ContactPage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/NachrichtGesendet"}`}
                           component={ContactPageDanke}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about"}`}
                           component={TeamPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service"}`}
                           component={ServicePage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/impressum"}`}
                           component={ImpressumPage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/datenschutz"}`}
                           component={DatenschutzPage}/>
                  
                    <Route path={`${process.env.PUBLIC_URL + "/home"}`}
                           component={HomeRR}/>
                  
                    <Route path={`${process.env.PUBLIC_URL + "/Portfolio"}`}
                           component={Portfolio}/>
                     
                           
                    <Route exact path={`${process.env.PUBLIC_URL + "/"}`}
                           component={HomeRR}
                    />

                     


                    <Route exact component={ErrorPage}/>
                </Switch>
                
              </ContentProvider>
            </Router>
        </Fragment>
    );
};

export default App;