import React,{useEffect} from 'react';
import {Link} from 'react-router-dom';

import {useContent} from '../../hooks/ContentContext';

const Portfolio = ({id,thumb,link, classes, title, category, variant}) => {
   
    const {state: {portfolioContent}} = useContent();

    useEffect(()=>{
        if(portfolioContent){
    }

    },[portfolioContent])
    
    return (
        <div className={`portfolio-item ${classes ? classes : ' '}`}>
            <Link to={`${process.env.PUBLIC_URL + "/"+link}`} className={'d-block'}
            onClick={() => {
                window.scrollTo(0, 0);
            }}
            >
                {variant === 'column' ? (
                    <div className="portfolio-image">
                        <img src={thumb} alt={title}/>
                    </div>

                ) : (
                    <div className="portfolio-image"
                    
                         style={{backgroundImage: `url(${thumb})`}}>
 
                             </div>
                )}
                <div className="portfolio-content">
                    <h4 className="title">{title}</h4>
                    <div className="category"><span>{category}</span></div>
                </div>
            </Link>
        </div>
    );
};

export default Portfolio;
