import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

const Service = ({title, content}) => {
    return (
        <div className="service-item">
            <h3>{title}</h3>
             <BlockContent blocks={content}/>
        </div>
    );
};

export default Service;