
import React, {Fragment} from 'react';
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import Impressum from "../../templates/Impressum";
import FooterNav from "../../components/footer/FooterThree";

const AboutPage = () => {
    {/** <div className={`main-wrapper`}> */}
    return (
       
             <Fragment>
             <Header classes={'project-header fixed-top'}/>
            <SideHeader mobile={true}/>


            <Impressum/>
            <FooterNav position={'static'}/>
            </Fragment>
      
    );
};

export default AboutPage;