import React, {Fragment} from 'react';

import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import ContentAboutPage from "../../templates/AboutPage";
import FooterThree from "../../components/footer/FooterThree";

const AboutPage = (props) => {
    return (
        <Fragment>
            <Header classes={'project-header fixed-top'}/>
            <SideHeader mobile={true}/>
            <ContentAboutPage id={props.id}/>
            <FooterThree position='static'/>
            </Fragment>
    );
};

export default AboutPage;