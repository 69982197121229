import React,{Fragment} from 'react';
import Contact from "../../container/contact";
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import FooterNav from "../../components/footer/FooterThree";

import {Container, Row, Col} from 'react-bootstrap';


const ContactPage = () => {
    return (
        <Fragment>
              <Header classes={'project-header fixed-top'}/>
            <SideHeader mobile={true}/>
            <div class="bg-projekte section">
            <Container>
                <Row>
                    <Col  >
                    
                        <div className="rr_introText">
                            <h2>Danke für Ihre Nachricht</h2>
                            <p>
                            Wir werden uns in Kürze bei Ihnen melden.
                            </p>
                        </div>

                    </Col>
                </Row>
            </Container>

            <Contact/>
            </div>
            <FooterNav position={'static'}/>
        </Fragment>
    );
};

export default ContactPage;