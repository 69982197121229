import React from 'react';
import {NavLink} from "react-router-dom";
import {useContent} from '../../hooks/ContentContext';

const NavItem = () => {
    const {state: {portfolioContent,team}} = useContent();

    const closeSideMenu = () => {
        const sideMenuWrap = document.querySelector(".side-header");
        const overlay = document.querySelector('.side-menu-overlay');
        sideMenuWrap.classList.remove('side-menu-open');
        overlay.classList.remove('overlay-show');
    };

    return (
        <React.Fragment>

<li >
                <NavLink
                    exact={true}
                    to={`${process.env.PUBLIC_URL + "/"}`}
                    activeClassName={'active'}>
                    {"Home"}
                </NavLink>
                </li>

<li className='menu-item-has-children'>
                <NavLink
                    exact={false}
                    to={`${process.env.PUBLIC_URL + "about"}`}
                    activeClassName={'active'}>
                    {"About"}
                </NavLink>

                <ul className="sub-menu">
                        {team && team.map(about => (
                            <li key={about._id}>
                                <NavLink to={`${process.env.PUBLIC_URL + about.link.current}`}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    closeSideMenu();
                                }}
                                >
                                    {about.name}</NavLink>
                            </li>
                                ))}
                </ul>
     </li>


     <li className='menu-item-has-children'>
                <NavLink
                    exact={false}
                    to={`${process.env.PUBLIC_URL + "Portfolio"}`}
                    activeClassName={'active'}>
                    {"Portfolio"}
                </NavLink>

                <ul className="sub-menu">
                        {portfolioContent && portfolioContent.map(projekt => (
                            <li key={projekt._id}>
                                <NavLink to={`${process.env.PUBLIC_URL + projekt.link.current}`}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    closeSideMenu();
                                }}
                                >
                                    {projekt.titel}</NavLink>
                            </li>
                                ))}
                </ul>
     </li>

            <li>
                <NavLink
                    exact={false}
                    to={`${process.env.PUBLIC_URL + "Kontakt"}`}
                    activeClassName={'active'}>
                    {"Kontakt"}
                </NavLink>
            </li>





        </React.Fragment>
        
    );
};

export default NavItem;
