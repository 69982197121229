import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'

const FooterThree = ({position}) => {
    return (
        <div className={`footer-section section position-${position} pt-30 pb-30`}>
            <Container fluid>
                <Row className={'align-items-center'}>
                <Col className={'footer-copyright text-left'}>
                        <p>&copy; {new Date().getFullYear()} Rabot Rummel</p>
                    </Col>
                    <Col className={'footer-copyright text-right'}>
                    <a href="/impressum">Impressum </a> &nbsp;
                    <a href="/datenschutz"> Datenschutz</a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FooterThree;
