import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'

const FooterThree = ({next, prev,position}) => {
    return (
        
        <div className={`footer-section section position-${position} pt-30 pb-30`}>
            <Container fluid>
                <div className="row align-items-center">
                <div className="footer-copyright col-lg-4 col-12 order-lg-1 order-3">
                       
                    </div>
                        <div className="col-lg-4 col-12 order-lg-2 order-1 mb-sm-15 mb-xs-15">
                        <div className="portfolio-slider-5-nav">
                            <button
                                type="button"
                                className="slick-prev slick-arrow"
                                onClick={prev}> prev
                            </button>
                            <button
                                type="button"
                                className="slick-next slick-arrow"
                                onClick={next}>next
                            </button>
                        </div>
                    </div>
</div>
                   
            </Container>
        </div>
    );
};

export default FooterThree;
