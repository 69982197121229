import React from 'react';
import PageContainer from "../../container/CustomPage";

import Datenschutz from "../../container/Datenschutz";

const ContentAboutPage = () => {
    return (
        <div class="bg-projekte section">
           <Datenschutz type={'page'}/>
        </div>
    );
};

export default ContentAboutPage;