import React,{Fragment} from 'react';
import Contact from "../../container/contact";
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import FooterNav from "../../components/footer/FooterThree";
import {Helmet} from "react-helmet";

const ContactPage = () => {
    return (
        <Fragment>
              <Helmet>
            <title>Kontakt</title>
        <meta name="description" content="Nehmen Sie Kontakt zu uns auf. Wir beraten Sie kompetent." />
    </Helmet>

              <Header classes={'project-header fixed-top'}/>
            <SideHeader mobile={true}/>
            <div class="bg-projekte section">
            <Contact/>
            </div>
            <FooterNav position={'static'}/>
        </Fragment>
    );
};

export default ContactPage;