import React, {useState,useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PortfolioDetailsContent from "./content";

import sanityClient from "../../../client";
import imageUrlBuilder from '@sanity/image-url';
import {useContent} from '../../../hooks/ContentContext';
import {useHistory} from 'react-router-dom';
import {Helmet} from "react-helmet";


const TemplatePortfolioDetails = (Props) => {
    const history = useHistory();
    const sanityImageBuilder = imageUrlBuilder(sanityClient);
    const {state: {portfolioContent}} = useContent();
const [portfolioProjekt, setPortfolioProjekt] = useState(null);
const [projektIndex, setProjektIndex] = useState(null);
    function getSanityImageUrl(source) {
        return sanityImageBuilder.image(source)
      }
    
      useEffect(()=>{
        if(portfolioContent){
            let projekt=portfolioContent.find(element => element._id ==Props.id);
            let index=portfolioContent.findIndex(element => element._id ==Props.id);

            setPortfolioProjekt(projekt);
            setProjektIndex(index);
        }
      },[portfolioContent])

      function nextProject(){
          let projekt=projektIndex;
          projekt++;
          if(projekt>portfolioContent.length-1)projekt=0;
          history.push(portfolioContent[projekt].link.current);
      }

      function prevProject(){
        let projekt=projektIndex;
        projekt--;
        if(projekt<0)projekt=portfolioContent.length-1;
        history.push(portfolioContent[projekt].link.current);
    }


    return (
        <div className="bg-projekte section">

<Helmet>
<title>{portfolioProjekt ? portfolioProjekt.titel:"Rabot Rummel Projekt"}</title>
<meta name="description" content={portfolioProjekt ? portfolioProjekt.beschreibung.de[0].children[0].text:"Hier finden sie Details zu diesem Rabot Rummel Projekt."} />
</Helmet>

            <Container>
            <div className="portfolio-slider-5-nav">
            <button
                                type="button"
                                className="slick-prev slick-arrow"
                                onClick={()=>prevProject()}> prev
                            </button>
                            <button
                                type="button"
                                className="slick-next slick-arrow"
                                onClick={()=>nextProject()}>next
                            </button>
</div>
<br/><br/>
                <Row>
                    <Col lg={7}>
                        {
                        portfolioProjekt && portfolioProjekt.medien.map((media,index)=>{
                            return(
                            <span>

                            {media.video && <video key={media._key}  alt={media.titel}  src={portfolioProjekt.mediaURLs[index]} autoPlay loop muted playsInline type="video/mp4"/>}
                            {media.videoaudio && <video key={media._key}  alt={media.titel}  src={portfolioProjekt.mediaURLs[index]} playsInline controls type="video/mp4"/>}
                            {media._type=="image" && <img style={{"margin-bottom":"10px"}} key={media._key} width="745px" height="520px" alt={media.titel}  
                            src={
                                    media.hotspot?
                                    getSanityImageUrl(media.asset).width(745).height(520).focalPoint(media.hotspot.x, media.hotspot.y).fit("crop").crop("focalpoint").url()
                                    :
                                    getSanityImageUrl(media.asset).width(745).height(520).url()
                                } />}
                                 <br/>                           
                          </span>)
                        })
                      }
                        </Col>

                    <Col lg={5}>
                         
                         {portfolioProjekt &&
                        <PortfolioDetailsContent
                            titel={portfolioProjekt.titel}
                            projekttyp={portfolioProjekt.projekttyp}
                            beschreibung={portfolioProjekt.beschreibung.de }
                            meta={{"klient":portfolioProjekt.klient,
                                    "webseite":portfolioProjekt.webseite,
                                "datum":portfolioProjekt.projektdatum,
                                "service":portfolioProjekt.service}
                            }
                        />
                        }
                    </Col>
                </Row>
                
             </Container>
            </div> 
       
    );
};
{/** </PageContainer>*/}
export default TemplatePortfolioDetails;

